import React from 'react'

export default class Radio extends React.Component {
  constructor(props){
    super(props);
    this.state={}
  }
  
  setOptionsToChild(event) {
    const { name, value } = event.target;
    this.setState({
      [name] : value
      });
    this.props.setOptions(event)
  }
  
  render() {
    const displayCenterClass= " flex align-center";
    let options = this.props.options;
    return ( 
      <div className={this.props.customOptionParentCSS + " mb-6"} onChange={event => this.setOptionsToChild(event)}>
        {
          options.map((o, i) => (
            <div key={i} className={!!this.props.customClass ? this.props.customClass + displayCenterClass : "mb-4" + displayCenterClass}>
              <label className={displayCenterClass}>
                <input className="mr-6 w-5 h-5 cursor-pointer" type="radio" name={o.name} value={o.value}  defaultChecked={o.value === this.props.value}/>
                {o.label}
              </label>
            </div>
          ))
        }
      </div>
     )
  }
}
