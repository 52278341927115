import React from 'react'
import Button from '../components/Button'

import { SiteContext, ContextProviderComponent } from '../context/mainContext'
import CartLink from '../components/CartLink'
import LogLink from '../components/LogLink'
import { Link } from 'gatsby'
import Image from '../components/Image'
import { setEventProperties,setPageProperties,isBrowser } from '../../utils/zeotap';
import { DENOMINATION } from '../../providers/inventoryProvider'
import Radio from '../components/formComponents/Radio'
import { FaBatteryQuarter } from 'react-icons/fa'
import { nanoid } from 'nanoid'
import { omit } from 'ramda'
import {slugify, titleIfy} from "../../utils/helpers";
import inventory from '../../providers/inventory'


const colorOptions = [{name: 'color', label: 'Red', value: 'Red'}, {name: 'color', label: 'Black', value: 'Black'}];
const ItemView = (props) => {
  const item = inventory.find(i => i.name == props.pageContext.content.name)
  const { price, image, name, description } = item
  const { context: { addToCart, clearCart }} = props
  if(item.catalog === 'automobiles') {
    item.availableConfigs.map(config => { 
      item['vehicle_'+config.configName] = config.options[0].value;
    });
  }
  
  const setColor = (event) => {
    item.vehicle_color = event.target.value;
  }

  const setConfig = (event, configName) => {
    item['vehicle_'+configName] = event.target.value;
  }

  function addItemToCart (item) {
    addToCart(item);
    const event_id = nanoid();
    const itemAttributes = omit(['id','name', 'price', 'catalog', 'brand', 'categories', 'currentInventory', 'image', 'description'], item)
    // window.fbq('track', 'AddToCart', {
    //   ...itemAttributes,
    //   event_id,
    //   eventID: event_id,
    //   productID:item.id,
    //   product_name:item.name,
    //   product_price:+item.price,
    //   product_price_currency:DENOMINATION,
    //   product_quantity:+item.currentInventory,
    // });
    setEventProperties('addToCart',{
      ...itemAttributes,
      id: event_id,
      productID:item.id,
      product_name:item.name,
      product_price:+item.price,
      product_price_currency:DENOMINATION,
      product_quantity:+item.currentInventory})
  }

  function addConfiguration(item, eventName) {
    
    clearCart();
    addToCart(item);
    const event_id = nanoid();
    const itemAttributes = omit(['id','name', 'price', 'catalog', 'brand', 'categories', 'currentInventory', 'image', 'description', 'availableConfigs' ], item);

    setEventProperties(eventName,{
      ...itemAttributes,
      id: event_id,
      productID:item.id,
      product_name:item.name,
      product_price:+item.price,
      product_price_currency:DENOMINATION,
      product_quantity:+item.currentInventory})
  }

  return (
    <>
      <CartLink />
      <LogLink />
      <div className="py-12 flex flex-1 flex-col
      md:flex-row
      w-full
      my-0 mx-auto">
        <div className="w-full md:w-1/2 h-112 flex flex-1 bg-light hover:bg-light-200">
          <div className="py-16 p10 flex flex-1 justify-center items-center">
            <Image src={image} className="max-w-lg m-0 max-h-96 w-64 md:w-full showcase" alt="Inventory item"  />
          </div>
        </div>
        <div className="pt-2 px-0 md:px-10 pb-8 w-full md:w-1/2">
          <h1 className="text-5xl font-light">{name}</h1>
          <h2 className="text-2xl tracking-tighter">{(item.price.includes('£') ? '': DENOMINATION)}{price}</h2>
          <p className="text-gray-600 text-sm">{description}</p>
          {item.catalog === 'automobiles' ? ( <span className='flex flex-row flex-wrap'>{item.availableConfigs.map((x, i) => (<span className='mx-10' key={i}> <h4>{x.configLabel}</h4> <Radio key={i} setOptions={(event) => {setConfig(event, x.configName)} } options={x.options} value={x.options[0].value}/></span>))}</span>) : ''}
          
          {item.catalog === 'automobiles' ? 
          (
            <span className='flex flex-row'>
               <Link to="/checkout/" className="mx-10 ">
            <Button
            full
            title="Find Dealer"
            onClick={() => addConfiguration(item, 'findDealer')}
          />
          </Link>
          <Link to="/contact-us/" className="mx-10">
          <Button
          full
          title="Request Test Drive"
          onClick={() => addConfiguration(item, 'testDrive')}
        />
        </Link>
            </span>
           
          )
         : 
        
        <Button
         full
         title="Add to Cart"
         onClick={() => addItemToCart(item)}
       />}
        </div>
      </div>
    </>
  )
}


function ItemViewWithContext(props) {
  setPageProperties({page_category:titleIfy(slugify(props.path)),page_category_level:'2',page_name:props.pageContext.title,page_domain:isBrowser() && window.location.hostname})
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {
          context =>  <ItemView {...props} context={context} />
        }
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}


export default ItemViewWithContext
